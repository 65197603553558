/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import * as yup from 'yup';
import { Card, ContentLayout, FormItem } from '@components/molecules';
import { styled } from 'styled-components';
import { Button } from '@components/atoms';
import { useFormik } from 'formik';
import { RightButtonWrapper } from '@components/utils/layout-utils';
import { useAlertStore } from '@store/useAlertStore';
import { useAgencyStore } from '@store/useAgencyStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useToastStore } from '@store/useToastStore';
import Skeleton from '@components/molecules/Skeleton';
import { PartnerForm } from '@pages/private/customer/partners/types/PartnerForm';
import Input from '@components/v2/antd/data-entry/Input';

import Select from '@components/v2/antd/data-entry/Select';
import AddressSearchModal from '@components/templates/AddressSearchModal';
import PartnerMemberTable from '@pages/private/customer/partners/components/PartnerMemberTable';
import Upload from '@components/v2/antd/data-entry/Upload';
import { Switch } from 'antd';
import AddMemberModal from '@pages/private/customer/partners/components/AddMemberModal';
import {
  createPartner,
  fetchPartnerDetail,
  updatePartner,
} from '../../../../@apis/partners';
import { CreatePartnerBody } from '../../../../@types/partner/params/CreatePartnerBody';
import { UpdatePartnerBody } from '../../../../@types/partner/params/UpdatePartnerBody';
import { fetchPartnerGroups } from '../../../../@apis/partner-groups';
import { Address } from '../../../../@types/common/models/Address';
import { PartnerGroupPartnerMember } from '../../../../@types/partner/models/PartnerMember';
import VirtualCallsTable from './components/VirtualCallsTable';
import AddVirtualCallModal from './components/AddVirtualCallModal';
import { deleteOvMember } from '../../../../@apis/members';
import AssistantTable from './components/AssistantTable';
import AddAssistantModal from './components/AddAssistantModal';
import {
  CONSULTATION_ACTIVE_HOURS,
  CONSULTATION_TIME_SOURCE_OPTIONS,
  ConsultationActiveHours,
  ConsultationTimeSources,
  MEDICAL_CHARGE_TYPE_OPTIONS,
  MedicalChargeType,
} from '../../../../@types/partner/constants/partner.constants';

const INITIAL_VALUES: PartnerForm = {
  partnerName: '',
  partnerCode: '',
  partnerEmail: '',
  displayName: '',
  partnerBusinessNumber: '',
  partnerInternationalTelCode: '82',
  partnerCeoName: '',
  partnerTel: '',
  partnerFax: '',
  partnerGroupId: undefined,
  partnerAddress: undefined,
  homepageType: undefined,
  templateCode: 'TEMPLATE_1',
  crmType: undefined,
  useMedicalCharge: true,
  useReservationSetting: true,
  channelId: undefined,
  channelAccessKey: undefined,
  channelAccessSecret: undefined,
  botName: undefined,
  groupAlarmName: undefined,
  wechatAppId: undefined,
  wechatOpenAccountId: undefined,
  wechatAppSecret: undefined,
  medicalChargeType: undefined,
};

function PartnerDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToastStore();
  const showAlert = useAlertStore((state) => state.show);
  const agencyOptions = useAgencyStore((state) => state.agencyOptions);

  const [addressSearchModalVisible, setAddressSearchModalVisible] =
    useState(false);
  const [addMemberModalVisible, setAddMemberModalVisible] = useState(false);
  const [addVirtualCallModalVisible, setAddVirtualCallModalVisible] =
    useState(false);
  const [addAssistantModalVisible, setAddAssistantModalVisible] =
    useState(false);
  const [dataFetching, setDataFetching] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(INITIAL_VALUES);
  const [partnerGroupOptions, setPartnerGroupOptions] = useState<
    { label: string; value: any }[]
  >([]);
  const [members, setMembers] = useState<PartnerGroupPartnerMember[]>([]);

  const formValidationSchema = yup.object({
    partnerName: yup.string().required(),
    partnerCode: yup.string().required(),
    partnerCeoName: yup.string().required(),
    partnerBusinessNumber: yup.string().required(),
    partnerInternationalTelCode: yup.string().optional(),
    partnerTel: yup.string().required(),
    partnerEmail: yup.lazy(() => {
      if (id) {
        return yup.string().optional();
      }
      return yup.string().email().required();
    }),
  });

  const formik = useFormik<PartnerForm>({
    initialValues: formInitialValues,
    onSubmit: async (values) => {
      const {
        partnerAddress,
        ogImageUrl,
        channelId,
        channelAccessKey,
        channelAccessSecret,
        botName,
        groupAlarmName,
        wechatAppId,
        wechatOpenAccountId,
        wechatAppSecret,
        isAiConsultation,
        ...rest
      } = values;
      const ogImage = ogImageUrl?.[0] || null;
      if (id) {
        await updateData(id, {
          ...partnerAddress,
          ...rest,
          displayName: values.displayName || null,
          ogImageUrl: ogImage,
          channelId: channelId || null,
          channelAccessKey: channelAccessKey || null,
          channelAccessSecret: channelAccessSecret || null,
          botName: botName || null,
          groupAlarmName: groupAlarmName || null,
          wechatAppId: wechatAppId || null,
          wechatOpenAccountId: wechatOpenAccountId || null,
          wechatAppSecret: wechatAppSecret || null,
          isAiConsultation,
        });
      } else {
        await addData({
          ...partnerAddress,
          ...rest,
          agencyId: Number(values.agencyId),
          displayName: values.displayName || null,
          partnerEmail: values.partnerEmail || '',
          isHeadOffice: false,
          isHomePageOpen: false,
          partnerCategoryId: 1,
          membershipId: 1,
          homepageType: values.homepageType || null,
          templateCode: values.templateCode,
          vatRate: '10',
          seoTitle: values.seoTitle || null,
          seoDescription: values.seoDescription || null,
          seoKeywords: values.seoKeywords || [],
          useMedicalCharge: values.useMedicalCharge,
          useReservationSetting: values.useReservationSetting,
          ogImageUrl: ogImage,
        });
      }
    },
    enableReinitialize: true,
    validationSchema: formValidationSchema,
    validateOnMount: true,
  });

  const fetchPartnerGroupOptions = useCallback(async () => {
    try {
      const { rows } = await fetchPartnerGroups({
        page: 1,
        pageSize: 9999,
      });
      setPartnerGroupOptions(
        rows.map(({ partnerGroupName, id }) => ({
          label: partnerGroupName,
          value: id,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const fetchDetailData = useCallback(async (id: number | string) => {
    try {
      const { row } = await fetchPartnerDetail(id);
      console.log(row);
      const {
        partnerName,
        partnerCode,
        partnerBusinessNumber,
        partnerCeoName,
        displayName,
        partnerTel,
        partnerInternationalTelCode,
        partnerFax,
        regionDepth1,
        regionDepth2,
        regionDepth3,
        zibun,
        zipCode,
        country,
        detail,
        street,
        lat,
        lng,
        homepageConfig,
        partnerGroupPartnerMembers = [],
        aiConsultationConfig,
        // 상세 정보
      } = row;
      console.log('partnerInternationalTelCode', partnerInternationalTelCode);

      let initialChannelId = '';
      let initialChannelAccessKey = '';
      let initialChannelAccessSecret = '';
      let initialBotName = '';
      let initialGroupAlarmName = '';
      let initialWechatAppId = '';
      let initialWechatOpenAccountId = '';
      let initialWechatAppSecret = '';
      let initialGptApiKey: string | undefined;
      let initialActiveHours: ConsultationActiveHours | undefined;
      let initialMedicalChargeType: MedicalChargeType | undefined;
      let initialIsAiConsultation = false;
      let initialConsultationTimeSource: ConsultationTimeSources | undefined;

      if (aiConsultationConfig) {
        const {
          channelId = '',
          channelAccessKey = '',
          channelAccessSecret = '',
          botName = '',
          groupAlarmName = '',
          wechatAppId = '',
          wechatOpenAccountId = '',
          wechatAppSecret = '',
          activeHours,
          gptApiKey,
          medicalChargeType,
          isAiConsultation,
          consultationTimeSource,
        } = aiConsultationConfig;

        if (channelId) {
          initialChannelId = channelId;
        }
        if (channelAccessKey) {
          initialChannelAccessKey = channelAccessKey;
        }
        if (channelAccessSecret) {
          initialChannelAccessSecret = channelAccessSecret;
        }
        if (botName) {
          initialBotName = botName;
        }
        if (groupAlarmName) {
          initialGroupAlarmName = groupAlarmName;
        }
        if (wechatAppId) {
          initialWechatAppId = wechatAppId;
        }
        if (wechatOpenAccountId) {
          initialWechatOpenAccountId = wechatOpenAccountId;
        }
        if (wechatAppSecret) {
          initialWechatAppSecret = wechatAppSecret;
        }
        initialGptApiKey = gptApiKey;
        initialActiveHours = activeHours;
        initialMedicalChargeType = medicalChargeType;

        initialIsAiConsultation = isAiConsultation;
        initialConsultationTimeSource = consultationTimeSource;
      }

      setMembers(partnerGroupPartnerMembers);

      let partnerAddress: Address | undefined;
      if (street && zibun && zipCode) {
        partnerAddress = {
          regionDepth1,
          regionDepth2,
          regionDepth3,
          zibun,
          zipCode,
          country,
          detail,
          street,
          lat,
          lng,
        };
      }

      setFormInitialValues({
        gptApiKey: initialGptApiKey,
        activeHours: initialActiveHours,
        medicalChargeType: initialMedicalChargeType,
        partnerAddress,
        partnerName,
        partnerCode,
        partnerBusinessNumber,
        partnerCeoName,
        displayName,
        partnerInternationalTelCode,
        partnerTel,
        partnerFax,
        templateCode: homepageConfig?.templateCode,
        homepageType: homepageConfig?.homepageType,
        seoTitle: homepageConfig?.seoTitle,
        seoDescription: homepageConfig?.seoDescription,
        seoKeywords: homepageConfig?.seoKeywords || [],
        useMedicalCharge: homepageConfig?.useMedicalCharge || false,
        useReservationSetting: homepageConfig?.useReservationSetting || false,
        ogImageUrl: homepageConfig?.ogImageUrl
          ? [homepageConfig.ogImageUrl]
          : [],
        channelId: initialChannelId,
        channelAccessKey: initialChannelAccessKey,
        channelAccessSecret: initialChannelAccessSecret,
        botName: initialBotName,
        groupAlarmName: initialGroupAlarmName,
        wechatAppId: initialWechatAppId,
        wechatOpenAccountId: initialWechatOpenAccountId,
        wechatAppSecret: initialWechatAppSecret,
        isAiConsultation: initialIsAiConsultation,
        consultationTimeSource: initialConsultationTimeSource,
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    (async function fetch() {
      setDataFetching(true);
      if (id) {
        await fetchDetailData(id);
      } else {
        await fetchPartnerGroupOptions();
      }
      setDataFetching(false);
    })();
  }, [id, fetchDetailData]);

  const reloadMembers = async () => {
    if (id) {
      try {
        const { row } = await fetchPartnerDetail(id);
        const { partnerGroupPartnerMembers = [] } = row;
        setMembers(partnerGroupPartnerMembers);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const addData = async (body: CreatePartnerBody) => {
    try {
      await createPartner(body);
      showToast({
        description: '파트너 정보가 등록되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const updateData = async (id: number | string, body: UpdatePartnerBody) => {
    try {
      await updatePartner(id, body);
      showToast({
        description: '파트너 정보가 수정되었어요',
        status: 'Primary',
      });
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  const requestDeleteMember = async (id: number) => {
    try {
      await deleteOvMember(id);
      await reloadMembers();
      showToast({
        description: '사용자가 삭제되었습니다',
        status: 'Red',
      });
    } catch (e) {
      console.error(e);
    }
  };

  const updateForm = (key: string, value: any) => {
    formik.setFieldValue(key, value);
  };

  const handleSaveClick = () => {
    let alertTitle = '파트너 등록';
    let alertMessage = '작성하신 내용대로 파트너 정보를 등록하시겠어요?';
    let actionLabel = '등록';
    if (id) {
      alertTitle = '파트너 수정';
      alertMessage = '작성하신 내용대로 정보를 수정하시겠어요?';
      actionLabel = '수정';
    }

    showAlert({
      size: 360,
      title: alertTitle,
      message: alertMessage,
      actions: [
        { label: '취소' },
        {
          label: actionLabel,
          onClick: async () => {
            formik.handleSubmit();
          },
        },
      ],
    });
  };

  const handleCancelClick = () => {
    if (formik.dirty) {
      showAlert({
        title: '등록 취소',
        message:
          '현재 페이지를 나가시면\n작성하신 내용은 저장되지 않아요.\n페이지를 나가시겠어요?',
        actions: [
          {
            label: '취소',
          },
          {
            label: '나가기',
            color: 'red',
            onClick: () => {
              navigate(-1);
            },
          },
        ],
      });
    } else {
      navigate(-1);
    }
  };
  const renderSelectForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PartnerForm;
    placeholder?: string;
    options: { label: string; value: any }[];
    mode?: 'tags' | 'multiple';
  }) => {
    const {
      labelName,
      required = false,
      key,
      placeholder,
      options,
      mode,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          style={{
            width: '100%',
          }}
          mode={mode}
          showSearch
          allowClear
          value={formik.values[key]}
          options={options}
          onChange={(value) => {
            if (value === null) {
              updateForm(key, null);
            } else {
              updateForm(key, value);
            }
          }}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderTagInput = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PartnerForm;
    placeholder?: string;
    options?: { label: string; value: string }[];
    separateWithSpaceAndComma?: boolean;
  }) => {
    const {
      labelName,
      required = false,
      key,
      placeholder,
      separateWithSpaceAndComma = false,
      options = [],
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    let tokenSeparators: string[] = [];
    if (separateWithSpaceAndComma) {
      tokenSeparators = [','];
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <Select
          mode="tags"
          style={{
            width: '100%',
          }}
          tokenSeparators={tokenSeparators}
          showSearch
          value={formik.values[key] || undefined}
          onChange={(values) => {
            updateForm(key, values);
          }}
          options={options}
          placeholder={placeholderText}
          id={key}
        />
      </FormItem>
    );
  };

  const renderAddressSearchForm = () => {
    let addressText = '';
    let detailText = '';
    const address = formik.values.partnerAddress;
    if (address) {
      const { street, detail } = address;
      addressText = street;
      detailText = detail || '';
    }

    return (
      <FormItem label={'주소'} optional>
        <div
          style={{
            width: '100%',
          }}
        >
          <Input
            value={addressText}
            className="input-container"
            placeholder={'주소 검색'}
            onClick={() => {
              setAddressSearchModalVisible(true);
            }}
          />
          <Input
            style={{
              marginTop: 12,
            }}
            value={detailText}
            onChange={(e) => {
              formik.setFieldValue('partnerAddress', {
                ...address,
                detail: e.target.value,
              });
            }}
            className="input-container"
            placeholder="상세 주소"
            disabled={!address}
          />
        </div>
      </FormItem>
    );
  };

  const renderInputForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PartnerForm;
    placeholder?: string;
    numberMode?: boolean;
    disabled?: boolean;
    infoLabel?: string;
  }) => {
    const {
      labelName,
      required = false,
      disabled,
      key,
      placeholder,
      infoLabel,
    } = info;
    let placeholderText = `${labelName} 입력`;
    if (placeholder) {
      placeholderText = placeholder;
    }

    return (
      <FormItem label={labelName} optional={!required}>
        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <Input
            id={key}
            name={key}
            value={formik.values[key] as string}
            onChange={(e) => {
              updateForm(key, e.target.value);
            }}
            className="input-container"
            placeholder={placeholderText}
            disabled={disabled}
          />
          {infoLabel && (
            <div
              style={{
                fontSize: 12,
                marginTop: 8,
              }}
            >
              <span>{infoLabel}</span>
            </div>
          )}
        </div>
      </FormItem>
    );
  };

  const renderSwitchForm = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PartnerForm;
  }) => {
    const { labelName, required = false, key } = info;

    return (
      <FormItem label={labelName} optional={!required}>
        <Switch
          checked={formik.values[key] as boolean}
          onChange={(checked) => {
            updateForm(key, checked);
          }}
        />
      </FormItem>
    );
  };

  const renderUpload = (info: {
    labelName: string;
    required?: boolean;
    key: keyof PartnerForm;
  }) => {
    const { labelName, required = false, key } = info;
    let values: string[] = [];
    if (Array.isArray(formik.values[key])) {
      values = formik.values[key] as string[];
    }
    return (
      <FormItem label={labelName} optional={!required}>
        <Upload
          initialImageUrls={values}
          onImageDataChange={(imageUrls: string[]) => {
            formik.setFieldValue(key, imageUrls);
          }}
        />
      </FormItem>
    );
  };

  const renderFormActions = () => {
    let saveButtonDisabled = !formik.isValid;

    if (id) {
      saveButtonDisabled = !formik.isValid || !formik.dirty;
    }

    return (
      <RightButtonWrapper>
        <Button
          onClick={handleCancelClick}
          buttonStyle="line"
          buttonColor="gray"
        >
          취소
        </Button>
        <Button disabled={saveButtonDisabled} onClick={handleSaveClick}>
          저장
        </Button>
      </RightButtonWrapper>
    );
  };

  return dataFetching ? (
    <Skeleton headerRight={renderFormActions()} />
  ) : (
    <ContentLayout headerRight={renderFormActions()}>
      <form onSubmit={formik.handleSubmit}>
        <FormSectionLayout>
          <Card title={'기본정보'}>
            <FormLayout>
              {!id && (
                <>
                  {renderSelectForm({
                    labelName: '대행사',
                    key: 'agencyId',
                    options: agencyOptions,
                    required: true,
                  })}
                  {renderSelectForm({
                    labelName: '파트너 그룹',
                    key: 'partnerGroupId',
                    options: partnerGroupOptions,
                    required: false,
                  })}
                </>
              )}
              {renderInputForm({
                labelName: '상호명',
                required: true,
                key: 'partnerName',
              })}
              {renderInputForm({
                labelName: '상호 노출명',
                required: false,
                key: 'displayName',
              })}
              {!id &&
                renderInputForm({
                  labelName: '파트너 코드',
                  required: true,
                  key: 'partnerCode',
                  disabled: !!id,
                  infoLabel: '파트너 코드는 생성 후 수정이 불가합니다.',
                })}
              {renderInputForm({
                labelName: '대표자명',
                required: true,
                key: 'partnerCeoName',
              })}
              {renderInputForm({
                labelName: '사업자번호',
                required: true,
                key: 'partnerBusinessNumber',
              })}
              {!id &&
                renderInputForm({
                  labelName: '이메일',
                  required: true,
                  key: 'partnerEmail',
                  infoLabel:
                    '부스트리에 최초 생성될 계정의 이메일을 입력해주세요.',
                })}

              {renderInputForm({
                labelName: '국가코드',
                required: false,
                key: 'partnerInternationalTelCode',
                infoLabel: '기본값은 +82 입니다.',
              })}
              {renderInputForm({
                labelName: '연락처',
                required: true,
                key: 'partnerTel',
                infoLabel: !id
                  ? '최초 사용자 계정의 초기 비밀번호 입니다.'
                  : '',
              })}
            </FormLayout>
          </Card>

          {id && (
            <Card
              title={'사용자 계정 정보'}
              right={
                <Button
                  onClick={() => {
                    setAddMemberModalVisible(true);
                  }}
                >
                  OV/OMA 등록
                </Button>
              }
            >
              <PartnerMemberTable
                members={members}
                onDeleteClick={async (member) => {
                  await requestDeleteMember(member.id);
                }}
              />
            </Card>
          )}

          {id && (
            <Card
              title={'가상번호 정보'}
              right={
                <Button
                  onClick={() => {
                    setAddVirtualCallModalVisible(true);
                  }}
                >
                  가상번호 등록
                </Button>
              }
            >
              <FormLayout>
                <FormItem label="등록된 가상번호" maxSize={720}>
                  <VirtualCallsTable partnerId={Number(id)} />
                </FormItem>
              </FormLayout>
            </Card>
          )}

          {id && (
            <Card
              title={'AI 상담 어시스턴트 정보'}
              right={
                <Button
                  onClick={() => {
                    setAddAssistantModalVisible(true);
                  }}
                >
                  어시스턴트 등록
                </Button>
              }
            >
              <AssistantTable partnerId={Number(id)} />
            </Card>
          )}

          <Card title={'부가정보'}>
            <FormLayout>
              {renderSelectForm({
                labelName: '템플릿',
                required: false,
                key: 'templateCode',
                options: [
                  { label: '템플릿 1', value: 'TEMPLATE_1' },
                  { label: '템플릿 2', value: 'TEMPLATE_2' },
                  { label: '템플릿 3', value: 'TEMPLATE_3' },
                  { label: '템플릿 4', value: 'TEMPLATE_4' },
                  { label: '템플릿 5', value: 'TEMPLATE_5' },
                  { label: '템플릿 6', value: 'TEMPLATE_6' },
                  { label: '템플릿 7', value: 'TEMPLATE_7' },
                  { label: '글로벌 템플릿 1', value: 'GLOBAL_TEMPLATE_1' },
                ],
              })}
              {renderSelectForm({
                labelName: '홈페이지타입',
                required: false,
                key: 'homepageType',
                options: [
                  { label: '브랜딩', value: 'BRANDING' },
                  { label: '커머스', value: 'COMMERCE' },
                ],
              })}
              {renderInputForm({
                labelName: 'SEO 제목',
                required: false,
                key: 'seoTitle',
              })}
              {renderInputForm({
                labelName: 'SEO 설명',
                required: false,
                key: 'seoDescription',
              })}
              {renderTagInput({
                labelName: 'SEO 키워드',
                key: 'seoKeywords',
                options: [],
                required: true,
                separateWithSpaceAndComma: true,
              })}
              {renderUpload({
                labelName: 'OG 이미지',
                required: false,
                key: 'ogImageUrl',
              })}
              {renderSwitchForm({
                labelName: '수가표 사용',
                key: 'useMedicalCharge',
                required: false,
              })}
              {renderSwitchForm({
                labelName: '신규 예약 사용',
                key: 'useReservationSetting',
                required: false,
              })}
              {renderInputForm({
                labelName: '팩스번호',
                required: false,
                key: 'partnerFax',
              })}
              {renderAddressSearchForm()}
            </FormLayout>
          </Card>

          {id && (
            <Card title={'AI 상담 정보'}>
              <FormLayout>
                {renderInputForm({
                  labelName: '채널톡 ID',
                  required: false,
                  key: 'channelId',
                  disabled: !!formInitialValues.channelId,
                })}
                {renderInputForm({
                  labelName: '채널톡 키',
                  required: false,
                  key: 'channelAccessKey',
                  disabled: !!formInitialValues.channelAccessKey,
                })}
                {renderInputForm({
                  labelName: '채널톡 비밀키',
                  required: false,
                  key: 'channelAccessSecret',
                  disabled: !!formInitialValues.channelAccessSecret,
                })}
                {renderInputForm({
                  labelName: '채널톡 봇이름',
                  required: false,
                  key: 'botName',
                  disabled: !!formInitialValues.botName,
                })}
                {renderInputForm({
                  labelName: '채널톡 상담신청그룹명',
                  required: false,
                  key: 'groupAlarmName',
                  disabled: !!formInitialValues.groupAlarmName,
                })}
                {renderInputForm({
                  labelName: '위챗 오픈계정ID',
                  required: false,
                  key: 'wechatOpenAccountId',
                  disabled: !!formInitialValues.wechatOpenAccountId,
                })}
                {renderInputForm({
                  labelName: '위챗 앱 ID',
                  required: false,
                  key: 'wechatAppId',
                  disabled: !!formInitialValues.wechatAppId,
                })}
                {renderInputForm({
                  labelName: '위챗 앱 비밀키',
                  required: false,
                  key: 'wechatAppSecret',
                  disabled: !!formInitialValues.wechatAppSecret,
                })}
                {renderInputForm({
                  labelName: 'GPT API 키',
                  required: false,
                  key: 'gptApiKey',
                  disabled: !!formInitialValues.gptApiKey,
                })}
                {renderSelectForm({
                  labelName: 'AI코디 운영시간',
                  required: false,
                  options: CONSULTATION_ACTIVE_HOURS,
                  key: 'activeHours',
                })}
                {renderSelectForm({
                  labelName: 'AI 상담 수가표 타입',
                  required: false,
                  options: MEDICAL_CHARGE_TYPE_OPTIONS,
                  key: 'medicalChargeType',
                })}
                {renderSelectForm({
                  labelName: 'AI 상담 운영시간 참조채널',
                  required: false,
                  options: CONSULTATION_TIME_SOURCE_OPTIONS,
                  key: 'consultationTimeSource',
                })}
                {renderSelectForm({
                  labelName: 'AI 상담 사용여부',
                  required: false,
                  options: [
                    {
                      label: '사용',
                      value: true,
                    },
                    {
                      label: '미사용',
                      value: false,
                    },
                  ],
                  key: 'isAiConsultation',
                })}
              </FormLayout>
            </Card>
          )}
        </FormSectionLayout>
      </form>

      {addressSearchModalVisible && (
        <AddressSearchModal
          onClose={() => {
            setAddressSearchModalVisible(false);
          }}
          onAddressSelect={(address) => {
            let detailAddress;
            if (formik.values.partnerAddress) {
              detailAddress = formik.values.partnerAddress.detail;
            }
            formik.setFieldValue('partnerAddress', {
              ...address,
              detail: detailAddress,
            });
          }}
        />
      )}

      {addVirtualCallModalVisible && (
        <AddVirtualCallModal
          partnerId={Number(id)}
          onClose={() => setAddVirtualCallModalVisible(false)}
        />
      )}

      {addAssistantModalVisible && (
        <AddAssistantModal
          partnerId={Number(id)}
          onClose={() => setAddAssistantModalVisible(false)}
        />
      )}
      {addMemberModalVisible && (
        <AddMemberModal
          onDataUpdated={async () => {
            await reloadMembers();
          }}
          partnerId={Number(id)}
          onClose={() => setAddMemberModalVisible(false)}
        />
      )}
    </ContentLayout>
  );
}

const FormSectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const PrefixText = styled.div`
  position: absolute;
  padding-left: 6px;
  top: 0;
  left: 0;
  line-height: 32px;
  font-size: 12px;
`;
export const FormLayout = styled.div`
  display: grid;
  width: 100%;
  gap: 24px;
`;
export default PartnerDetailPage;
